





























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {CreateLocationDTO} from "@/api";


@Component
export default class LocationsCreate extends Vue {
  @Prop(Number) readonly organizationId;

  public valid = false;
  public dialog: boolean = false;

  public formData = {
    name: '',
    latlong: ''
  }

  public async mounted() {
    this.reset();
  }

  public reset() {
    this.formData = {
      name: '',
      latlong: ''
    }

    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const arr = this.formData.latlong.split(',').map(Number);

      if (arr.length !== 2 || isNaN(arr[0]) || isNaN(arr[1])) {
        this.$store.commit('ui/addErrorNotification', 'Invalid latitude/longitude format')
        return;
      }

      const dto: CreateLocationDTO =  {
        organization_id: this.organizationId,
        name: this.formData.name,
        latitude: arr[0],
        longitude: arr[1]
      }

      await this.$store.dispatch('locations/create', { data: dto })
      await this.$router.push({
        name: 'main-admin-organisations-edit',
        params: {organisationId: this.organizationId},
      });
    }
  }
}
